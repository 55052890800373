@media (min-width: 1500px) {
  .container {
      width: 1470px;
  }
}

body {
  background-color: $background-grey-color;
  color: $text-color;
  font-family: $source-sans-fonts;
  font-weight: 300;
}

.tag-header {
  color: $hover-color;
}

.tag-list .btn-default {
  color: $white-text-color;
  background-color: $background-dark-grey-color;

  .badge {
    color: $text-color;
    background-color: $background-color;
  }
}


.tag-body {
  a {
    text-decoration: none;
    border-bottom: 1px dotted $border-color;
    letter-spacing: $letter-spacing;
    font-family: $raleway-fonts;
    color: $text-color;
    text-transform: uppercase;
    font-size: 15px;

    -moz-transition: color 0.2s ease;
    -ms-transition: color 0.2s ease;
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;

    &:hover {
      color: $hover-color;
    }
  }

  .post-date {
    color: $light-text-color;
    font-size: 11px;
    padding-left: 15px;
  }
}
