$raleway-fonts: 'Raleway', Helvetica, sans-serif;
$source-sans-fonts: 'Source Sans Pro', Helvetica, sans-serif;

$letter-spacing: 0.25em;

$background-color: #fff;
$background-grey-color: #f4f4f4;
$background-dark-grey-color: #646464;

$border-color: #ccc;

$dark-text-color: #3c3b3b;
$hover-color: #cc3433;
$light-text-color: #aaa;
$text-color: #646464;
$white-text-color: #fff;
