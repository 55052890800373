.header {
  background-color: $background-color;
  font-family: $raleway-fonts;
  font-weight: 700;
  letter-spacing: $letter-spacing;
  text-transform: uppercase;

  .navbar-nav {
    margin-top: 15px;
  }

  .navbar-nav > li > a {
    color: $dark-text-color;
    -moz-transition: color 0.2s ease;
    -ms-transition: color 0.2s ease;
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;

    &:hover { color: $hover-color; }
  }

  .navbar-brand {
    margin-top: -5px;

    img {
      height: 60px;
    }
  }

  .navbar-header button {
    margin-top: 20px;
  }

  .navbar-collapse.collapse,
  .navbar-collapse.collapsing {
    background-color: $background-color;
    border: none;
  }

  .container {
    margin-bottom: 15px;
  }

  .dropdown-menu > li > span {
    display: block;
    padding: 3px 20px;
  }

  @media (max-width: 767px) {
    .dropdown-menu > li > span {
      padding: 5px 15px 5px 25px;
    }
  }
}
