.thumbnail {
  letter-spacing: $letter-spacing;

  a {
    color: $dark-text-color;
    font-family: $raleway-fonts;
    font-size: 14px;
    font-weight: 700;
    text-decoration: none;
    text-transform: uppercase;

    -moz-transition: color 0.2s ease;
    -ms-transition: color 0.2s ease;
    -webkit-transition: color 0.2s ease;
    transition: color 0.2s ease;

    &:hover, &:focus {
      text-decoration: none;
    }

    &:hover {
      color: $hover-color;
    }
  }

  h3 {
    margin-bottom: 5px;
    margin-top: 16px;
  }

  img {
    -webkit-filter: grayscale(100%);
    filter: grayscale(100%);

    -moz-transition: filter 0.4s ease,-webkit-filter 0.4s ease;
    -ms-transition: filter 0.4s ease,-webkit-filter 0.4s ease;
    -webkit-transition: filter 0.4s ease,-webkit-filter 0.4s ease;
    transition: filter 0.4s ease,-webkit-filter 0.4s ease;

    &:hover {
      -webkit-filter: none;
      filter: none;
    }
  }

  time.published {
    font-family: $raleway-fonts;
    font-size: 11px;
    text-transform: uppercase;
  }

  .caption {
    padding-left: 23px;
    padding-right: 23px;
  }
}
